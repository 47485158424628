import 'ol/ol.css';
import {
    Map,
    View
} from 'ol';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
// import XYZSource from 'ol/source/XYZ';
import OSM from 'ol/source/OSM';
import VectorSource from 'ol/source/Vector';
import ClusterSource from 'ol/source/Cluster';
import {
    fromLonLat
} from 'ol/proj';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import {
    Fill,
    Stroke,
    Icon,
    Style,
    Circle,
    Text
} from 'ol/style';
import Overlay from 'ol/Overlay';
import {
    defaults as defaultInteractions
} from 'ol/interaction';
import GeoJSON from 'ol/format/GeoJSON';
import {
    Control,
    defaults as defaultControls
} from 'ol/control';

var isMobile = navigator.userAgent.match(/Android|BlackBerry|iPhone|iPod|iPad|Opera Mini|IEMobile/i);
var home = fromLonLat([13.4583, 50.9294]); // Die "Mitte von Sachsen"
var homeZoom = 9;
const view = new View({
    center: home,
    zoom: homeZoom,
    minZoom: 8,
    maxZoom: 12,
});
view.on('change:resolution', (event) => {
    var z=view.getZoom();
    var overlays = document.querySelectorAll('.multiply');

    console.log("zoom changed to " + z);
    if(z>10) {
        overlays[0].style.display = "none";
    }
    else {
        overlays[0].style.display = "inline";
    }
});

class NavigateHomeControl extends Control {
    /**
     * @param {Object} [opt_options] Control options.
     */
    constructor(opt_options) {
        const options = opt_options || {};

        const button = document.createElement('button');
        button.innerHTML = '&#8962;';

        const element = document.createElement('div');
        element.className = 'homeButton ol-unselectable ol-control';
        element.appendChild(button);

        super({
            element: element,
            target: options.target,
        });

        button.addEventListener('click', this.handleNavigateHome.bind(this), false);
    }

    handleNavigateHome() {
        this.getMap().getView().setCenter(home);
        this.getMap().getView().setZoom(homeZoom);
    }
}

const map = new Map({
    controls: defaultControls().extend([new NavigateHomeControl()]),
    interactions: defaultInteractions({
        mouseWheelZoom: false,
        dragPan: isMobile ? false : true,
    }),
    target: 'map-container',
    view: view
});

const tileLayer=new TileLayer({
//    className: 'multiply',
    source: new OSM()
});
// tileLayer.on('prerender', (evt) => {
//     // return
//     if (evt.context) {
//       const context = evt.context;
//       context.filter = 'brightness(80%)';
//       context.globalCompositeOperation = 'source-over';
//     }
// });
  
// const layer = new VectorLayer({
//     source: new VectorSource({
//         features: [
//             new Feature({
//                 geometry: new Point(fromLonLat([11.107, 51.303]))
//             })
//         ]
//     })
// });
// map.addLayer(layer);

const greyedLayer = new VectorLayer({
    className: 'multiply',
    source: new VectorSource({
        format: new GeoJSON(),
        url: '/greyed.geojson'
    }),
    style: new Style({
        fill: new Fill({
            color: '#00000080'
        }),
    }),
    name: 'greyed',
});

const highlightLayer = new VectorLayer({
    className: 'multiply',
    source: new VectorSource({
        format: new GeoJSON(),
        url: '/sachsen.geojson'
    }),
    style: new Style({
        fill: new Fill({
            color: '#f9e035'
        }),
        // stroke: new Stroke({
        //     color: '#319FD3',
        //     width: 1
        // }),
    }),
    name: 'highlight',
});
// highlightLayer.on('prerender', (evt) => {
//     // return
//     if (evt.context) {
//       const context = evt.context;
//       context.filter = 'brightness(80%)';
//       context.globalCompositeOperation = 'source-over';
//     }
// });
map.addLayer(tileLayer);
map.addLayer(highlightLayer);
map.addLayer(greyedLayer);


const iconStyle = new Style({
    image: new Icon({
        // anchor: [23, 48], //carota
        // anchor: [25, 46], // lauch.png
        // anchor: [6, 49], //schwarze-moehre
         anchor: [2, 29],
        anchorXUnits: 'pixels',
        anchorYUnits: 'pixels',
        // src: 'carota.png'
        // src: 'lauch.png'
        // src: 'schwarze_moehre.png' // Icons made by <a href="https://www.flaticon.com/authors/freepik" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon"> www.flaticon.com</a>
        src: 'map-marker.png'
    })
});

const iconFeatures = [];

async function fetchBetriebe() {
	const response = await fetch("/betriebe_sachsen.json");
	const betriebe = await response.json();

    betriebe.forEach(setMarker);
    const vectorSource = new VectorSource({
        features: iconFeatures
    });
    // Without Clusters
    //const vectorLayer = new VectorLayer({
    //    source: vectorSource,
    //    name: 'markerlayer',
    //});
    //map.addLayer(vectorLayer);

    // Clusters
    var clusterSource = new ClusterSource({
        distance: 15,
        source: vectorSource
    });

    var styleCache = {};

    var clusters = new VectorLayer({
        source: clusterSource,
        name: 'clusterlayer',
        style: function(feature) {
            var size = feature.get('features').length;
            if (size > 0) {
                var style = styleCache[size];
                if (!style) {
                    if (size == 1) {
                        style = new Style({
                            image: new Circle({
                                radius: 10,
                                stroke: new Stroke({
                                    // color: '#1B8B61',
                                    color: '#fff',
                                    width: 1,
                                }),
                                fill: new Fill({
                                    // color: 'rgba(27, 139, 97)'
                                    color: '#1B8B61',
                                    // color: '#000',
                                })
                            }),
                        });
                    } else {
                        style = new Style({
                            image: new Circle({
                                radius: 13,
                                stroke: new Stroke({
                                    color: '#49ff12',
                                    width: 2,
                                }),
                                fill: new Fill({
                                    // color: 'rgba(27, 139, 97)'
                                    //color: '#2ad495',
                                    color: '#45ba21',
                                    //color: '#000',
                                })
                            }),
                            text: new Text({
                                text: size.toString(),
                                fill: new Fill({
                                    //color: '#126647'
                                    color: '#0e4a34'
                                }),
                                font: "11pt Sans serif",
                                // offsetX: -1,
                                offsetY: 1,
                                color: '#0e4a34',
                                // stroke: new Stroke({
                                //     color: '#1B8B61',
                                //     width: 2,
                                // }),
                            })
                        });
                    }
                    styleCache[size] = style;
                }
                return style;
            }
        },
        //minResolution: 2001
    });

    map.addLayer(clusters);
}
fetchBetriebe();

function setMarker(loc) {
    //console.log(loc);
    if(loc[0] && loc[1] && loc[2] && loc[5]!="1") {
            console.log('set ' + loc[0] + ' ' + loc[2] + ' ' + loc[1]);
        const iconFeature = new Feature({
            geometry: new Point(fromLonLat([loc[2], loc[1]])),
            name: loc[0],
            produkte: loc[3],
            permalink: loc[4]
        });
        //iconFeature.setStyle(iconStyle);
        iconFeatures.push(iconFeature);
    }
}

// Popups
const popover = document.getElementById('mypopover');

const popup = new Overlay({
    element: popover,
    positioning: 'bottom-left',
    // offset: [10, -10]
    // stopEvent: false,
    // offset: [40, -65]
});
map.addOverlay(popup);

map.on('click', function(evt) {
    const feature = map.forEachFeatureAtPixel(evt.pixel,
        function(feature) {
            return feature;
        }, {
            layerFilter: function(layer) {
                return layer.get('name') === 'clusterlayer';
            }
    });
    popover.classList.add('hide-po');
    if (feature) {
        const coordinates = feature.getGeometry().getCoordinates();
        popup.setPosition(coordinates);
        const clusteredFeatures = feature.get('features');
        if (clusteredFeatures.length == 1) {
            popover.innerHTML = mkContent(clusteredFeatures[0]);
            popover.classList.remove('hide-po');
        } else {
            view.setZoom(view.getZoom() + 4);
            console.log(view.getCenter());
            console.log(feature.getGeometry().getCoordinates());

            view.setCenter(feature.getGeometry().getCoordinates());
        }
    }
});

// change mouse cursor when over marker
let hideablePopup = false;
map.on('pointermove', function(e) {
    if (e.dragging) {
        //$(element).popover('destroy');
        return;
    }
    const pixel = map.getEventPixel(e.originalEvent);
    const hit = map.hasFeatureAtPixel(pixel, {
        layerFilter: function(layer) {
            return layer.get('name') === 'clusterlayer';
        }
    });
    if (hit) {
        const feature = map.forEachFeatureAtPixel(e.pixel, function(feature) {
            return feature;
        });

        const clusteredFeatures = feature.get('features');
        if (clusteredFeatures.length != 1) {
            popover.classList.add('hide-po');
            const coordinates = feature.getGeometry().getCoordinates();
            //console.log(coordinates);
            popup.setPosition(coordinates);
            popover.innerHTML = "<i>In dieser Region gibt es mehrere Solawis.<br>Zum Zoomen bitte klicken</i>";
            hideablePopup = true;
            popover.classList.remove('hide-po');
        }

        map.getTargetElement().style.cursor = 'pointer';
    } else {
        if (hideablePopup) {
            popover.classList.add('hide-po');
            hideablePopup = false;
        }
        map.getTargetElement().style.cursor = '';
    }
});

function mkContent(feature) {
    let content = '<h3>' + feature.get('name') + '</h3>';
    let permalink = feature.get('permalink');

    let produkte = feature.get('produkte');
    if (produkte) {
        content += '<p>' + produkte + '</p>';
    }

    if (permalink) {
        content += '<button onclick="window.location.href=' + "'" + permalink + "'" + '" target="_top">Portrait</button>';
    }
    return content;
}
